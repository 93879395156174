import * as React from 'react';
import Link from './link';

import { StyledNextPrevious } from './styles/PageNavigationButtons';

const NextPrevious = ({ mdx, nav }) => {
  // Ensure the nav array is sorted numerically by index
  const sortedNav = [...nav].sort((a, b) => {
    const getIndex = (item) => {
      if (!item.index) return []; // Return an empty array if index is missing
      return item.index.split('.').map((num) => Number(num) || 0); // Safely parse numbers
    };

    const aIndex = getIndex(a);
    const bIndex = getIndex(b);

    for (let i = 0; i < Math.max(aIndex.length, bIndex.length); i++) {
      const aVal = aIndex[i] ?? 0; // Default to 0 for missing values
      const bVal = bIndex[i] ?? 0;
      if (aVal !== bVal) {
        return aVal - bVal;
      }
    }
    return 0;
  });

  let currentIndex;

  // Find the current page's index in the sorted navigation
  sortedNav.forEach((el, index) => {
    if (el && el.url === mdx.fields.slug) {
      currentIndex = index;
    }
  });

  const nextInfo = {};
  const previousInfo = {};

  if (currentIndex === undefined) {
    // Handle undefined index
    if (sortedNav[0]) {
      nextInfo.url = sortedNav[0].url;
      nextInfo.title = sortedNav[0].title;
    }
    previousInfo.url = null;
    previousInfo.title = null;
    currentIndex = -1;
  } else if (currentIndex === 0) {
    // First page
    nextInfo.url = sortedNav[currentIndex + 1]?.url ?? null;
    nextInfo.title = sortedNav[currentIndex + 1]?.title ?? null;
    previousInfo.url = null;
    previousInfo.title = null;
  } else if (currentIndex === sortedNav.length - 1) {
    // Last page
    nextInfo.url = null;
    nextInfo.title = null;
    previousInfo.url = sortedNav[currentIndex - 1]?.url ?? null;
    previousInfo.title = sortedNav[currentIndex - 1]?.title ?? null;
  } else {
    // Any other page
    nextInfo.url = sortedNav[currentIndex + 1]?.url ?? null;
    nextInfo.title = sortedNav[currentIndex + 1]?.title ?? null;
    previousInfo.url = sortedNav[currentIndex - 1]?.url ?? null;
    previousInfo.title = sortedNav[currentIndex - 1]?.title ?? null;
  }

  return (
    <StyledNextPrevious>
      {previousInfo.url && currentIndex >= 0 && (
        <Link to={`/course${previousInfo.url}`} className="previousBtn">
          <div className="leftArrow">
            <svg
              preserveAspectRatio="xMidYMid meet"
              height="1em"
              width="1em"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="currentColor"
              className="_13gjrqj"
            >
              <g>
                <line x1="19" y1="12" x2="5" y2="12" />
                <polyline points="12 19 5 12 12 5" />
              </g>
            </svg>
          </div>
          <div className="preRightWrapper">
            <div className="smallContent">
              <span>Previous</span>
            </div>
            <div className="nextPreviousTitle">
              <span>{previousInfo.title}</span>
            </div>
          </div>
        </Link>
      )}
      {nextInfo.url && currentIndex >= 0 && (
        <Link to={`/course${nextInfo.url}`} className="nextBtn">
          <div className="nextRightWrapper">
            <div className="smallContent">
              <span>Next</span>
            </div>
            <div className="nextPreviousTitle">
              <span>{nextInfo.title}</span>
            </div>
          </div>
          <div className="rightArrow">
            <svg
              preserveAspectRatio="xMidYMid meet"
              height="1em"
              width="1em"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="currentColor"
              className="_13gjrqj"
            >
              <g>
                <line x1="5" y1="12" x2="19" y2="12" />
                <polyline points="12 5 19 12 12 19" />
              </g>
            </svg>
          </div>
        </Link>
      )}
    </StyledNextPrevious>
  );
};

export default NextPrevious;



// import * as React from 'react';
// import Link from './link';

// import { StyledNextPrevious } from './styles/PageNavigationButtons';

// const NextPrevious = ({ mdx, nav }) => {
//   let currentIndex;

//   const currentPaginationInfo = nav.map((el, index) => {
//     if (el && el.url === mdx.fields.slug) {
//       currentIndex = index;
//     }
//   });

//   const nextInfo = {};

//   const previousInfo = {};

//   if (currentIndex === undefined) {
//     // index
//     if (nav[0]) {
//       nextInfo.url = nav[0].url;
//       nextInfo.title = nav[0].title;
//     }
//     previousInfo.url = null;
//     previousInfo.title = null;
//     currentIndex = -1;
//   } else if (currentIndex === 0) {
//     // first page
//     nextInfo.url = nav[currentIndex + 1] ? nav[currentIndex + 1].url : null;
//     nextInfo.title = nav[currentIndex + 1] ? nav[currentIndex + 1].title : null;
//     previousInfo.url = null;
//     previousInfo.title = null;
//   } else if (currentIndex === nav.length - 1) {
//     // last page
//     nextInfo.url = null;
//     nextInfo.title = null;
//     previousInfo.url = nav[currentIndex - 1] ? nav[currentIndex - 1].url : null;
//     previousInfo.title = nav[currentIndex - 1] ? nav[currentIndex - 1].title : null;
//   } else if (currentIndex) {
//     // any other page
//     nextInfo.url = nav[currentIndex + 1].url;
//     nextInfo.title = nav[currentIndex + 1].title;
//     if (nav[currentIndex - 1]) {
//       previousInfo.url = nav[currentIndex - 1].url;
//       previousInfo.title = nav[currentIndex - 1].title;
//     }
//   }

//   return (
//     <StyledNextPrevious>
//       {previousInfo.url && currentIndex >= 0 ? (
//         <Link to={`/course${nav[currentIndex - 1].url}`} className={'previousBtn'}>
//           <div className={'leftArrow'}>
//             <svg
//               preserveAspectRatio="xMidYMid meet"
//               height="1em"
//               width="1em"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 24 24"
//               strokeWidth="2"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               stroke="currentColor"
//               className="_13gjrqj"
//             >
//               <g>
//                 <line x1="19" y1="12" x2="5" y2="12" />
//                 <polyline points="12 19 5 12 12 5" />
//               </g>
//             </svg>
//           </div>
//           <div className={'preRightWrapper'}>
//             <div className={'smallContent'}>
//               <span>Previous</span>
//             </div>
//             <div className={'nextPreviousTitle'}>
//               <span>{nav[currentIndex - 1].title}</span>
//             </div>
//           </div>
//         </Link>
//       ) : null}
//       {nextInfo.url && currentIndex >= 0 ? (
//         <Link to={`/course${nav[currentIndex + 1].url}`} className={'nextBtn'}>
//           <div className={'nextRightWrapper'}>
//             <div className={'smallContent'}>
//               <span>Next</span>
//             </div>
//             <div className={'nextPreviousTitle'}>
//               <span>{nav[currentIndex + 1] && nav[currentIndex + 1].title}</span>
//             </div>
//           </div>
//           <div className={'rightArrow'}>
//             <svg
//               preserveAspectRatio="xMidYMid meet"
//               height="1em"
//               width="1em"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 24 24"
//               strokeWidth="2"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               stroke="currentColor"
//               className="_13gjrqj"
//             >
//               <g>
//                 <line x1="5" y1="12" x2="19" y2="12" />
//                 <polyline points="12 5 19 12 12 19" />
//               </g>
//             </svg>
//           </div>
//         </Link>
//       ) : null}
//     </StyledNextPrevious>
//   );
// };

// export default NextPrevious;