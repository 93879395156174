import * as React from 'react';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';

import ThemeProvider from './theme/themeProvider';
import mdxComponents from './mdxComponents';
import Sidebar from './sidebar';
import RightSidebar from './rightSidebar';
import config from '../../config.js';
import { Link } from 'gatsby';

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.background};

  .sideBarUL li a {
    color: ${({ theme }) => theme.colors.text};
  }

  .sideBarUL .item > a:hover {
    background-color: #eff6ff;
    color: #1d4ed8;

    /* background: #F8F8F8 */
  }

  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

const Content = styled('main')`
  display: flex;
  flex-grow: 1;
  margin: 0px 88px;
  padding-top: 3rem;
  background: ${({ theme }) => theme.colors.background};

  table tr {
    background: ${({ theme }) => theme.colors.background};
  }

  @media only screen and (max-width: 1023px) {
    padding-left: 0;
    margin: 0 10px;
    padding-top: 3rem;
  }
`;

const MaxWidth = styled('div')`
  @media only screen and (max-width: 50rem) {
    width: 100%;
    position: relative;
  }
`;

const LeftSideBarWidth = styled('div')`
  width: 298px;
`;

const RightSideBarWidth = styled('div')`
  width: 224px;
`;

const Layout = ({ children, location }) => (
  <ThemeProvider location={location}>
    <MDXProvider components={mdxComponents}>
      <Wrapper>
        <LeftSideBarWidth className={'hiddenMobile'}>
          <Sidebar location={location} />
        </LeftSideBarWidth>
        {config.sidebar.title ? (
          <div
            className={'sidebarTitle sideBarShow'}
            dangerouslySetInnerHTML={{ __html: config.sidebar.title }}
          />
        ) : null}
        <Content>
          <MaxWidth>{children}</MaxWidth>
        </Content>
        <RightSideBarWidth className={'hiddenMobile'}>
          <RightSidebar location={location} />
        </RightSideBarWidth>
      </Wrapper>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", fontWeight: "100", backgroundColor: "#b5b5b50f", padding: "25px 0px", borderTop: "1px solid #80808045", rowGap: "5px"}}>
        <p>© 2023 - 2024 CalcPrep</p>
        <div style={{display: "flex", columnGap: "20px"}}>
          <Link to="/contact" style={{color: "blue"}}>[Contact]</Link>
          <Link to="/privacy-and-terms#privacystatement" style={{color: "blue"}}>[Privacy Statement]</Link>
          <Link to="/privacy-and-terms#termsofuse" style={{color: "blue"}}>[Terms of Use]</Link>
        </div>
        <p style={{fontSize: "small"}}>Last Updated: 12/14/2024</p>
        <p style={{fontStyle: "italic", fontSize: "small", margin: "5px 20px 0px 20px", textAlign: "center"}}>AP® and CLEP® are trademarks registered by the College Board, which is not affiliated with, and does not endorse, this website.</p>
      </div>
    </MDXProvider>
  </ThemeProvider>
);

export default Layout;
